export default new class NavMobileMenu {
    constructor() {
        this.burger = '.burger'
        this.menu = 'header .navigation-wrap'

        this.init();
    }
    init() {
        const self = this

        $(this.burger).on('click', function() {
            $(self.menu).toggleClass('show')
            self.checkStatus($(self.menu))
        })
        $(`${this.menu} .close-menu`).on('click', function() {
            $(self.menu).removeClass('show')
            self.checkStatus($(self.menu))
        })
        $(document).mouseup(function (e) {
            let menu = $(self.menu)
            if (menu.has(e.target).length === 0){
                menu.removeClass('show')
                self.checkStatus($(self.menu))
            }
        })
    }
    checkStatus(elem) {
        if(elem.hasClass('show')){
            $('body').css({
                'overflow-y': 'hidden'
            })
        } else {
            $('body').css({
                'overflow-y': ''
            })
        }
    }
}